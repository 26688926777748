import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from 'react-redux';
import { authActions } from '../../_store';

//import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import React, { useState } from "react";
import { FaEye } from "react-icons/fa6";
import { FaEyeSlash } from "react-icons/fa6";


export { Login };

function Login() {

    const recaptchaRef = React.createRef();
    function onChange(value) {
        console.log("Captcha value:", value);
      }
      
    //*const auth = useSelector(x => x.auth.value);
    const dispatch = useDispatch();
    //const logout = () => dispatch(authActions.logout());

    // form validation rules 
    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit({ username, password }) {
        return dispatch(authActions.login({ username, password }));
    }

    /*const windowWidth = useRef(window.innerWidth);
    const windowHeight = useRef(window.innerHeight);*/

    const [ showPassword, setShowPassword ] = useState(false);

    return (
        <div id='signin-box' className='top-padding-login'>

            <div className='container-login'>
                {/* 
                <div id="banner-wrapper">
                    <div className="container">

                        <div id="banner">
                            <h2>Put something cool here!</h2>
                            <div id="banner-span">
                                And put something almost as cool here, but a bit longer ...
                            </div>
                            
                        </div>

                    </div>
                </div>
                */}
                <div className='login-form'>
                    <h2>Sign in</h2>
                    <form onSubmit={handleSubmit(onSubmit)} >

                        <div className="form-group mt-20">
                            <input name="username" type="email" autoComplete="username" autoFocus="autofocus" 
                            placeholder="Email" {...register('username')} className={`form-control ${errors.username ? 'email-field error' : ''}`} />
                            <label data-content="Email" htmlFor="email">
                                <span className="sr-only">
                                    Email
                                </span>
                            </label>
                            <div className="error error-message">{errors.username?.message}</div>
                        </div>

                        <div className="flex-container">
                            <div className="flex-child">
                            <div className="form-group">
                                <input name="password" type={showPassword ? "text" : "password"} autoComplete="password" 
                                autoFocus="autofocus"
                                placeholder="Password" {...register('password')} className={`form-control password-login-field ${errors.password ? 'email-field error' : ''}`} />
                                
                                <label data-content="Password" htmlFor="password">
                                    <span className="sr-only">
                                        Password
                                    </span>                                        
                                </label>
                                <div className="error error-message">{errors.password?.message}</div>                      
                            </div>
                            </div>
                            
                            <div className="flex-child password-show">
                                {showPassword ? <FaEyeSlash onClick={() => {setShowPassword(!showPassword)}} /> : <FaEye onClick={() => {setShowPassword(!showPassword)}} />}
                            </div>
                            
                        </div>
                        

                        {/*
                        <div className='options-in-line'>
                        <div className='display-options-in-line'>
                            <div className="login-center-options">
                            <div className="remember-div">
                                <input type="checkbox" id="remember-checkbox" />
                                <label htmlFor="remember-checkbox">
                                Remember for 30 days
                                </label>
                            </div>
                            </div>

                            <div className="forgot-pass-link">
                            <Link to="../forgot-password" className="btn btn-link forgot-pass-link">
                                Forgot password?
                            </Link>
                            </div>
                        </div>
                        </div>
                        */}

                        <div className="login-submit">
                        <button disabled={isSubmitting} type="submit">
                            {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                            Sign In
                        </button>
                        </div>
                    </form>

                    <div className="form-helper">
                        <div className="link-login-helper">
                            <Link to="../forgot-password" className="btn btn-link forgot-pass-link">
                                Forgot password?
                            </Link>
                        </div>
                        <div className="link-login-helper">
                            <Link to="../register" className="btn btn-link forgot-pass-link">Register</Link>
                        </div>
                        <div className="recaptcha-center">
                            <form onSubmit={() => { recaptchaRef.current.execute(); }}>
                                <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                onChange={onChange}
                                />
                            </form>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    );
}
