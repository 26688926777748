import { useEffect, useState } from 'react';
import { history } from '../../_helpers';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { userActions } from '../../_store';

export { Confirm };

function Confirm() {
    const dispatch = useDispatch();

    const auth = useSelector(x => x.auth.value);
    const [user, setUser] = useState(null);

    const sendConfirmEmail = async (e) => {
        e.preventDefault();

        try {
            await dispatch(userActions.resend(auth.id)).unwrap();
            // redirect to login page and display success alert
            history.navigate('/account/login');
            toast.success('Registration successful, please check your email to complete the process.', {
                position: "top-center"
            });
        } catch (error){};
    }

    useEffect(() => {
        const user = dispatch(userActions.getById(auth.id));
        setUser(user);
    }, []);

    return (
        <div>            
            <br/><br/>
            {user && user.sent_confirmed_email ? (
                <>
                    <h1>Confirm email sent!</h1>
                    <span className='fs-4 text-secondary'>Please check your inbox and click the link.</span>
                </>                
            ) : (
                <>
                    <h1>Confirm your account</h1>
                    <h2>Please click the button to resend the confirm email.</h2>
                    <br/><br/>
                    <button className="btn btn-sm btn-success mb-2" onClick={sendConfirmEmail}>
                        Send confirm email
                    </button>
                </>
            )}
        </div>
    );
}
