import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { authActions } from '../_store';
import { fetchWrapper, axiosInstance } from '../_helpers';

const URL_BASE_API = `${process.env.REACT_APP_URL_BASE_API}/api/`;

// create slice

const name = 'users';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const userActions = { ...slice.actions, ...extraActions };
export const usersReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        list: null,
        item: null
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/users`;

    return {
        register: register(),
        getAll: getAll(),
        getById: getById(),
        update: update(),
        delete: _delete(),
        verify: verify(),
        resend: resendConfirmEmail(),
        forgot: forgotPasswordEmail(),
        generate: generateRPToken(),
        reset: resetPasswordEmail()
    };

    function register() {
        return createAsyncThunk(
            `${name}/register`,
            async (user) => (await axiosInstance.post(URL_BASE_API + "auth/signup", user)).data
        );
    }

    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async () => (await axiosInstance.get(URL_BASE_API + "user/all")).data
        );
    }

    function getById() {
        return createAsyncThunk(
            `${name}/getById`,
            async (id) => (await axiosInstance.get(`${URL_BASE_API}user/${id}`)).data
            //async (id) => await fetchWrapper.get(`${baseUrl}/${id}`)
        );
    }

    function update() {
        return createAsyncThunk(
            `${name}/update`,
            async function ({ id, data }, { getState, dispatch }) {
                await fetchWrapper.put(`${baseUrl}/${id}`, data);
                //async (user) => (await axiosInstance.post(URL_BASE_API + "auth/signup", user)).data
                // update stored user if the logged in user updated their own record
                const auth = getState().auth.value;
                if (id === auth?.id.toString()) {
                    // update local storage
                    const user = { ...auth, ...data };
                    localStorage.setItem('auth', JSON.stringify(user));

                    // update auth user in redux state
                    dispatch(authActions.setAuth(user));
                }
            }
        );
    }

    // prefixed with underscore because delete is a reserved word in javascript
    function _delete() {
        return createAsyncThunk(
            `${name}/delete`,
            async function (id, { getState, dispatch }) {
                await fetchWrapper.delete(`${baseUrl}/${id}`);

                // auto logout if the logged in user deleted their own record
                if (id === getState().auth.value?.id) {
                    dispatch(authActions.logout());
                }
            }
        );
    }

    function verify() {
        return createAsyncThunk(
            `${name}/verify`,
            async (token) => (await axiosInstance.get(`${URL_BASE_API}email/verify/${token}`)).data
        );
    }

    function resendConfirmEmail() {
        return createAsyncThunk(
            `${name}/resend`,
            async (id) => (await axiosInstance.get(`${URL_BASE_API}email/resend/${id}`)).data
        );
    }
    
    function forgotPasswordEmail() {
        return createAsyncThunk(
            `${name}/forgot`,
            async (email) => (await axiosInstance.post(`${URL_BASE_API}email/forgot-password`,{email:email})).data
        );
    }

    function generateRPToken() {
        return createAsyncThunk(
            `${name}/generate`,
            async (token) => (await axiosInstance.get(`${URL_BASE_API}email/generate-reset-password/${token}`)).data
        );
    }

    function resetPasswordEmail() {
        return createAsyncThunk(
            `${name}/reset`,
            async ({ token, password, confirmPassword }) => (await axiosInstance.post(`${URL_BASE_API}email/reset-password`, { token:token, password:password, confirmPassword:confirmPassword })).data
        );
    }
}

function createExtraReducers() {
    return (builder) => {
        getAll();
        getById();
        _delete();
        verify();

        function getAll() {
            var { pending, fulfilled, rejected } = extraActions.getAll;
            builder
                .addCase(pending, (state) => {
                    state.list = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.list = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.list = { error: action.error };
                });
        }

        function getById() {
            var { pending, fulfilled, rejected } = extraActions.getById;
            builder
                .addCase(pending, (state) => {
                    state.item = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.item = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.item = { error: action.error };
                });
        }

        function _delete() {
            var { pending, fulfilled, rejected } = extraActions.delete;
            builder
                .addCase(pending, (state, action) => {
                    const user = state.list.value.find(x => x.id === action.meta.arg);
                    user.isDeleting = true;
                })
                .addCase(fulfilled, (state, action) => {
                    state.list.value = state.list.value.filter(x => x.id !== action.meta.arg);
                })
                .addCase(rejected, (state, action) => {
                    const user = state.list.value.find(x => x.id === action.meta.arg);
                    user.isDeleting = false;
                });
        }

        function verify() {
            var { pending, fulfilled, rejected } = extraActions.verify;
            builder
                .addCase(pending, (state) => {
                    state.list = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.list = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.list = { error: action.error };
                });
        }

    }
}
