import { HashLink as Link } from 'react-router-hash-link';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../../_store';
import { TiKeyOutline } from "react-icons/ti";
import { TiGift } from "react-icons/ti";
import { TiCreditCard } from "react-icons/ti";
import { TiCalendarOutline } from "react-icons/ti";
import { TfiReceipt } from "react-icons/tfi";
import { LuGem } from "react-icons/lu";
import { LuLogOut } from "react-icons/lu";
import { LuLineChart } from "react-icons/lu";

import image1 from "../../assets/images/pic5.jpg";

export { Home };

function Home() {
    const auth = useSelector(x => x.auth.value);
    const dispatch = useDispatch();
    const logout = () => dispatch(authActions.logout());

    return (
        <div className='nav-bottom'>

            <div className='top-padding-space'>
                <div className="row center-dashboard">
                    <div className="col-3 col-12-medium container">
                        <section>
                            <h2 className='dashboard-title'>Dashboard</h2>
                            <ul className="dashboard-link-list">
                                <li><a href="/">
                                    <TiKeyOutline /> Password manager
                                    </a>
                                </li>
                                <li><a href="/">
                                    <TiGift/> Refer Friends
                                    </a>
                                </li>
                                <li><a href="/">
                                    <TiCreditCard/> Add Credit Card
                                    </a>
                                </li>
                                <li><a href="/">
                                    <TiCalendarOutline/> Subscription
                                    </a>
                                </li>
                                <li><a href="/">
                                    <TfiReceipt/> Receipts
                                    </a>
                                </li>
                                <li><a href="/">
                                    <LuLineChart/> Reports and Stats
                                    </a>
                                </li>
                                <li><a href="/">
                                    <LuGem/> Premium content
                                    </a>
                                </li>
                            </ul>
                        </section>

                        <section className='welcome-title'>
                            <h1>Hi {auth?.username} !!</h1>
                            <p>You're logged in with your premium account.</p>
                            <h3>
                                <Link to="logout#root" onClick={logout}>
                                    <LuLogOut/> Sing out
                                </Link>
                            </h3>
                        </section>
                    </div>

                    <div id='imp-medium' className="col-9 col-12-medium container">

                        <div id="banner-wrapper">
                            <div className="container">

                                <div id="banner">
                                    <h2>Put something cool here!</h2>
                                    <div id="banner-span">
                                        And put something almost as cool here, but a bit longer ...
                                    </div>
                                    
                                </div>

                            </div>
                        </div>

                        <section id="content-three-column">
                            <div className="container">
                                <div className="row aln-center">
                                    <div className="col-4 col-12-medium container">

                                            <section>
                                                <header>
                                                    <h2>Who We Are</h2>
                                                    <h3>A subheading about who we are</h3>
                                                </header>
                                                <a href="/" className="feature-image"><img src={image1} alt="" /></a>
                                                <p>
                                                    Duis neque nisi, dapibus sed mattis quis, rutrum accumsan magna sed.
                                                    Suspendisse eu varius nibh. Suspendisse vitae magna eget odio amet mollis
                                                    justo facilisis quis. Sed sagittis amet lorem ipsum.
                                                </p>
                                            </section>

                                    </div>
                                    <div className="col-4 col-6-medium col-12-small container">

                                            <section>
                                                <header>
                                                    <h2>What We Do</h2>
                                                    <h3>A subheading about what we do</h3>
                                                </header>
                                                <ul className="check-list">
                                                    <li>Sed mattis quis rutrum accum</li>
                                                    <li>Eu varius nibh suspendisse lorem</li>
                                                    <li>Magna eget odio amet mollis justo</li>
                                                    <li>Facilisis quis sagittis mauris</li>
                                                    <li>Amet tellus gravida lorem ipsum</li>
                                                </ul>
                                            </section>

                                    </div>
                                    <div className="col-4 col-6-medium col-12-small container">

                                            <section>
                                                <header>
                                                    <h2>What We Do</h2>
                                                    <h3>A subheading about what we do</h3>
                                                </header>
                                                <ul className="check-list">
                                                    <li>Sed mattis quis rutrum accum</li>
                                                    <li>Eu varius nibh suspendisse lorem</li>
                                                    <li>Magna eget odio amet mollis justo</li>
                                                    <li>Facilisis quis sagittis mauris</li>
                                                    <li>Amet tellus gravida lorem ipsum</li>
                                                </ul>
                                            </section>

                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='content-one-column'>
                            <div className="container">
                                <div className="row aln-center">
                                    <div className="col-12 col-12-medium container">
                                            <section>
                                                <header>
                                                    <h2>Who We Are</h2>
                                                    <h3>A subheading about who we are</h3>
                                                </header>
                                                <a href="/" className="feature-image"><img src={image1} alt="" /></a>
                                                <p>
                                                    Duis neque nisi, dapibus sed mattis quis, rutrum accumsan magna sed.
                                                    Suspendisse eu varius nibh. Suspendisse vitae magna eget odio amet mollis
                                                    justo facilisis quis. Sed sagittis amet lorem ipsum.
                                                </p>
                                            </section>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>

        </div>
    );
}
