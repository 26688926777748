import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMediaQuery } from "react-responsive";
import { history } from '../_helpers';

export { PrivateRoute };

function PrivateRoute() {
    const auth = useSelector(x => x.auth.value);
    const isMobile = useMediaQuery({ maxWidth: "1150px" });

    if (!auth) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/account/login" state={{ from: history.location }} />
    }

    // authorized so return outlet for child routes
    
    return (
        <div className={((!isMobile) ? "container pt-4 pb-4" : "")}>
            <Outlet />
        </div>        
    );
}