import { useEffect, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { useSelector, useDispatch } from 'react-redux';
import { billingActions } from '../../_store';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {FcHeadset} from "react-icons/fc" 
import { CgCloseO } from "react-icons/cg";
import ReactPaginate from 'react-paginate';
/*import { toast } from 'react-toastify';
import { unwrapResult } from '@reduxjs/toolkit'*/

export { List };

function formatDate(string){
    //var options = { year: 'numeric', month: 'long', day: 'numeric' };
    var options = 'en-US'
    return new Date(string).toLocaleDateString([],options);
}

function List() {
    const payments = useSelector(x => x.billing.list);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [deletedId, setDeletedId] = useState('');
    const [deletedName, setDeletedName] = useState('');
    const [searchPayment, setSearchPayment] = useState(null);
    const [pageCount, setPageCount] = useState(0);

    const _delete = () => {
        setShow(false);
        dispatch(billingActions.delete(deletedId))
            .unwrap()
                .then(() => {
                    handleSearch()
                });
    }
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (id,name) => {
        setDeletedId(id);
        setDeletedName(name);
        setShow(true);
    }

    const handleChange = event => {
        setSearchPayment(event.target.value);
    };

    const handleClick = () => {
        setSearchPayment('');        
    };

    const handleSearch = () => {

        setCurrentPage(0);
        setPageOffset(0);

        const paramsPage = new URLSearchParams(window.location.search);
        paramsPage.set('page', 1);

        const term = (searchPayment==='' || searchPayment===null)?'':searchPayment;

        paramsPage.set('q', term);

        // Replace the current URL without reloading the page
        window.history.replaceState({}, '', `${window.location.pathname}?${paramsPage.toString()}`);

        dispatch(billingActions.getAllSearch({term: term, page: 1, limit: limit}))
            .unwrap()
                .then(payment => {
                    setPageCount(Math.ceil(payment.count/limit));
                })
                .catch((rejectedValueOrSerializedError) => {
                    /*toast.error(JSON.stringify(rejectedValueOrSerializedError), {
                        position: "top-center"
                        });*/
                    });
        
    };


    //const [pageOffset, setPageOffset] = useState(0);
    const params = new URLSearchParams(window.location.search);
    const pageParam = params.get('page');
    
    const limit = 3;
    //const pageCount = Math.ceil(data.total / limit)
    
    const initialPage = pageParam ? parseInt(pageParam, 10) - 1 : 0;
    // This is the current page that the component is on. It’s stored in a state variable so that 
    // the component can re-render whenever it changes. The setCurrentPage function is used to 
    // update this state variable.
    const [currentPage, setCurrentPage] = useState(initialPage);
    const [pageOffset, setPageOffset] = useState(initialPage);

    const handlePageChange = ({ selected }) => {
        const nextPage = selected;

        // Update the URL with the new page value
        const params = new URLSearchParams(window.location.search);
        params.set('page', nextPage + 1);

        // Replace the current URL without reloading the page
        window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);

        setCurrentPage(nextPage);
        setPageOffset(selected);
    };

    useEffect(() => {
        /*try {
            const params = new URLSearchParams(window.location.search);
            const qParam = params.get('q')
            setSearchPayment(qParam);
            
            const term = qParam;

            dispatch(billingActions.getAllSearch({term: term, page: currentPage+1, limit: limit}))
                .unwrap()
                    .then(payment => {
                        setPageCount(Math.ceil(payment.count/limit));
                    })
                    .catch((rejectedValueOrSerializedError) => {
                        toast.error(JSON.stringify(rejectedValueOrSerializedError), {
                            position: "top-center"
                          });
                      });
                      
        } catch (error) {}*/


        const params = new URLSearchParams(window.location.search);
        const qParam = params.get('q')
        setSearchPayment(qParam);
        
        const term = qParam;

        dispatch(billingActions.getAllSearch({term: term, page: currentPage+1, limit: limit}))
            .unwrap()
                .then(payment => {
                    setPageCount(Math.ceil(payment.count/limit));
                })
                .catch((rejectedValueOrSerializedError) => {
                    /*toast.error(JSON.stringify(rejectedValueOrSerializedError), {
                        position: "top-center"
                      });*/
                  });


    }, [currentPage]);

    return (
        <>
        <div className='nav-bottom top-padding-space list-item'>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                <Modal.Title>Delete Alert!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span className='text-danger'>
                        Are you sure to delete {deletedName}.
                        That action will not be able undone.
                    </span>                
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} className="btn btn-lg btn-primaty">
                    Close
                </Button>
                <Button variant="primary" onClick={_delete} className="btn btn-lg btn-danger">
                    Understood
                </Button>
                </Modal.Footer>
            </Modal>

            <h2>Payments</h2>
            <Link to="add" className="btn btn-lg btn-success">
                Add Payment
            </Link>

            <div className="parent">
                <div className="col-search">
                    <input type="text" id="searchPayment" onChange={handleChange} value={(searchPayment!=null)?searchPayment:''} className='input-form-search' placeholder="Search"/>
                </div>
                <div className="col-button-clear">
                    <CgCloseO size={25} onClick={handleClick} />
                </div>
                <div className="col-button-search">
                    <button onClick={handleSearch} className="btn btn-link nav-item nav-link">
                        Search
                    </button>
                </div>
            </div>

            <div className='line-text'>
                For coding, you need some good <FcHeadset />
            </div>
            
            { payments?.loading &&
                <div className="text-left">
                    <span className="spinner-border spinner-border-lg align-center"></span>
                </div>
            }
            
            {payments && payments.value && Object.keys(payments.value.rows).length > 0 ? (
                <>
                <div className="table-container">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th style={{ width: '20%' }}>Description</th>
                            <th style={{ width: '20%' }}>Payment Type</th>
                            <th style={{ width: '15%' }}>Amount</th>
                            <th style={{ width: '20%' }}>Billing Date</th>
                            <th style={{ width: '15%' }}>Expiration Date</th>
                            <th style={{ width: '10%' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {payments?.value?.rows.map(payment =>
                            <tr key={payment.id}>
                                <td>{payment?.description}</td>
                                <td>{payment?.payment_type?.description}</td>
                                <td>{payment.amount}</td>
                                <td>{ formatDate(payment.billing_date) }</td>
                                <td>{ formatDate(payment.expiration_date) }</td>
                                <td style={{ whiteSpace: 'nowrap' }}>
                                    <Link to={`edit/${payment.id}`} className="btn btn-lg btn-primary me-1">Edit</Link>
                                    <button onClick={() => handleShow(payment.id,payment?.payment_type?.description)} className="btn btn-lg btn-danger" style={{ width: '60px', marginLeft:'12px' }} disabled={payment.isDeleting}>
                                        {payment.isDeleting 
                                            ? <span className="spinner-border spinner-border-sm"></span>
                                            : <span>Delete</span>
                                        }
                                    </button>
                                </td>
                            </tr>
                        )}
                        {payments?.loading &&
                            <div className="text-left">
                                <span className="spinner-border spinner-border-lg align-center"></span>
                            </div>
                        }
                    </tbody>
                </table>
                
                <ReactPaginate
                        previousLabel="Previous"
                        nextLabel="Next"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageChange}
                        containerClassName="pagination"
                        activeClassName="active"
                        forcePage={pageOffset}
                />
                </div>
                </>
            ) : (
                <span className='fs-4 text-secondary'>No results.</span>
            )}
        </div>
        </>
    );
}
