import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

//import { alertActions } from '../_store';
import { history, axiosInstance } from '../_helpers';

// create slice

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// implementation

function createInitialState() {
    //localStorage.removeItem('auth');
    return {
        // initialize state from local storage to enable user to stay logged in
        value: JSON.parse(localStorage.getItem('auth'))
    }
}

function createReducers() {
    return {
        setAuth
    };

    function setAuth(state, action) {
        state.value = action.payload;
    }
}

function createExtraActions() {
    
    const URL_BASE_API = `${process.env.REACT_APP_URL_BASE_API}/api/auth`;
    return {
        login: login(),
        logout: logout()
    };

    function login() {
        return createAsyncThunk(
            `${name}/login`,
            async function ({ username, password }, { dispatch }) {
                //dispatch(alertActions.clear());
                //try {
                    const user = await axiosInstance.post(URL_BASE_API + "/signin", {username:username, password:password});

                    // set auth user in redux state
                    dispatch(authActions.setAuth(user.data));

                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('auth', JSON.stringify(user.data));
                    localStorage.setItem('accessToken', user.data.accessToken);
                    localStorage.setItem('refreshToken', user.data.refreshToken);
                    
                    // get return url from location state or default to home page
                    const { from } = history.location.state || { from: { pathname: '/' } };
                    history.navigate(from);
                /*} catch (error) {
                    dispatch(alertActions.error(error));
                }*/
            }
        );
    }

    function logout() {
        return createAsyncThunk(
            `${name}/logout`,
            function (arg, { dispatch }) {
                dispatch(authActions.setAuth(null));
                localStorage.removeItem('auth');
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                history.navigate('/account/login');
            }
        );
    }
}
