import { Routes, Route, Navigate, useLocation, matchPath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Login, Register, Verify, ForgotPassword, ResetPassword } from './';

export { AccountLayout };

function AccountLayout() {
    const auth = useSelector(x => x.auth.value);
    const location = useLocation();
    console.log(location.pathname)
    
    const matchProfile1 = matchPath({
        path: `/account/verify/:token`,
        exact: true
      },location.pathname);
    const matchProfile2 = matchPath({
        path: `/account/reset-password:token`,
        exact: true
      },location.pathname);

    // redirect to home if already logged in
    if (auth) {
        if (matchProfile1 === null && matchProfile2 === null){
            return <Navigate to="/" />;
        } 
    }

    return (
        <div className="">
            <div className="">
                <div className="">
                    <Routes>
                        <Route path="login" element={<Login />} />
                        <Route path="register" element={<Register />} />
                        <Route 
                            path="verify/:token" 
                            element={<Verify />}
                        />
                        <Route path="forgot-password" element={<ForgotPassword />} />
                        <Route 
                            path="reset-password/:token" 
                            element={<ResetPassword 
                        />} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
}
