import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { HashLink as Link } from 'react-router-hash-link';
import { history } from '../../_helpers';
import { userActions } from '../../_store';
import { toast } from 'react-toastify';

export { Register };

function Register() {
    const dispatch = useDispatch();

    const getCharacterValidationError = (str) => {
        return `Your password must have at least 1 ${str} character`;
    };
    
    // form validation rules 
    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('First Name is required'),
        lastName: Yup.string()
            .required('Last Name is required'),
        username: Yup.string()
            .required('Username is required'),
        password: Yup.string()
            .required('Password is required')
            // check minimum characters
            .min(6, 'Password must be at least 6 characters')
            // different error messages for different requirements
            .matches(/[0-9]/, getCharacterValidationError("digit"))
            .matches(/[a-z]/, getCharacterValidationError("lowercase")),
            //.matches(/[A-Z]/, getCharacterValidationError("uppercase")),
        confirmPassword: Yup.string()
            //.required("Please re-type your password")
            // use oneOf to match one of the values inside the array.
            // use "ref" to get the value of passwrod.
            //.oneOf([Yup.ref("password")], "Passwords does not match")
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    async function onSubmit(data) {
        const fields = {
            username:data.username,
            email:data.username,
            password:data.password,
            confirmPassword:data.confirmPassword
        }
        //dispatch(alertActions.clear());
        try {
            await dispatch(userActions.register(fields)).unwrap();


            // redirect to login page and display success alert
            history.navigate('/account/login');
            toast.success('Registration successful, please check your email to complete the process.', {
                position: "top-center"
            });
            /*dispatch(alertActions.success({ message: 'Registration successful', showAfterRedirect: true }));
            setTimeout(() => {
                dispatch(alertActions.clear());
              }, 4000);
            dispatch(alertActions.clear());*/
        } catch (error){};
    }

    return (
        <div className='container top-padding-space list-item bottom-register'>
            <div className="card m-3">
                <h1 className="card-header register-header">Register</h1>
                <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-3">
                            <label className="form-label">First Name</label>
                            <input id="fn-register" name="firstName" type="text" {...register('firstName')} className={`form-control input-size1 ${errors.firstName ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.firstName?.message}</div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Last Name</label>
                            <input id="ln-register" name="lastName" type="text" {...register('lastName')} className={`form-control input-size1 ${errors.lastName ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.lastName?.message}</div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Username</label>
                            <input id="un-register" name="username" type="text" {...register('username')} className={`form-control input-size1 ${errors.username ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.username?.message}</div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Password</label>
                            <input id="pw-register" name="password" type="password" {...register('password')} className={`form-control input-size1 ${errors.password ? 'is-invalid' : ''}`} autoComplete='on'/>
                            <div className="invalid-feedback">{errors.password?.message}</div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Confirm Password</label>
                            <input id="cp-register" name="confirmPassword" type="password" {...register('confirmPassword')} className={`form-control input-size1 ${errors.confirmPassword ? 'is-invalid' : ''}`} autoComplete='on'/>
                            <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
                        </div>
                        <button disabled={isSubmitting} className="btn btn-lg btn-primary register-btn">
                            {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                            Register
                        </button>
                        <Link to="/account/login#root" className="btn btn-lg btn-link register-btn">
                            Cancel
                        </Link>
                        
                    </form>
                </div>
            </div>
        </div>
    )
}
