import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';

import { history } from '../../_helpers';
import { alertActions, billingActions, paymentTypeActions } from '../../_store';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';

export { AddEdit };

function AddEdit() {
    const { id } = useParams();
    const [title, setTitle] = useState();
    const dispatch = useDispatch();
    const payment = useSelector(x => x.billing?.item);
    const paymentTypes = useSelector(x => x.payment_type?.list);
    const user = JSON.parse(localStorage.getItem('auth'));

    // form validation rules 
    const validationSchema = Yup.object().shape({
        payment_types_id: Yup.string()
            .required('Payment Type is required'),
        description: Yup.string()
            .required('Description is required'),
        amount: Yup.string()
            .required('Amount is required').default(0),
        billing_date: Yup.string()
            .required('Billing Date is required').nullable(),
        expiration_date: Yup.string()
            .required('Expiration Date is required').nullable()
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, control, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    useEffect(() => {
        if (id) {
            setTitle('Edit Payment');
            // fetch payment details into redux state and 
            // populate form fields with reset()
            dispatch(billingActions.getById(id)).unwrap()
                .then(payment => {
                    reset(payment);
                    dispatch(paymentTypeActions.getAll())
                    .then(() => {
                        
                    })
                    .catch((rejectedValueOrSerializedError) => {});
                })
                .catch((rejectedValueOrSerializedError) => {
                    /*toast.error(JSON.stringify(rejectedValueOrSerializedError), {
                        position: "top-center"
                        });*/
                    });

        } else {
            setTitle('Add Payment');
        }
        
        
    }, []);

    async function onSubmit(data) {
        try {
            // create or update payment based on id param
            let message;
            if (id) {
                await dispatch(billingActions.update({ id, data })).unwrap();
                message = 'Payment updated';
            } else {
                await dispatch(billingActions.register(data)).unwrap();
                message = 'Payment added';
            }

            // redirect to payment list with success message
            history.navigate('/billing');
            toast.success(message, {
                position: "top-center"
            });
        } catch (error) {
            dispatch(alertActions.error(error));
        }
    }

    return (
        <>
        <div className='nav-bottom top-padding-space'>
            <h1 className='add-edit-header'>{title}</h1>
            <br/>

            {payment?.loading &&
                <div className="text-center m-5">
                    <span className="spinner-border spinner-border-lg align-center"></span>
                </div>
            }
            {/*payment?.error &&
                <div className="text-center m-5">
                    <div className="text-danger">Error loading payment: {payment.error}</div>
                </div>
            */}

            {/*payment && payment.value && Object.keys(payment.value).length > 0 ? (*/
                <form onSubmit={handleSubmit(onSubmit)} className="slider-form-group">
                    <input type="hidden" {...register("users_id", { value: user.id })} />
                    <div className="row ">
                        <div className="col-6 col-12-medium container">
                            <label className="form-label">Payment Type</label>
                            <select {...register("payment_types_id")} defaultValue=""
                                className={`form-select form-select-lg input-size1 ${errors.payment_types_id ? 'is-invalid' : ''}`}>
                                    <option value="" disabled>Please select an option...</option>
                                    {paymentTypes?.value?.map(v =>
                                        <option key={v.value} value={v.value}>{v.label}</option>
                                    )}
                            </select>
                            <div className="invalid-feedback">{errors.payment_types_id?.message}</div>
                        </div>
                        
                        <div className="col-6 col-12-medium container">
                            <div className='list-item-right-margin'>
                                <label className="form-label">Description</label>
                                <div className='input-size2'>
                                    <input name="description" type="text" {...register('description')} className={`form-control input-size1 ${errors.description ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.description?.message}</div>
                                </div>
                            </div>                            
                        </div>
                    </div>

                    <div className="row ">
                        <div className="col-6 col-12-medium container">
                            <label className="form-label">Amount</label>
                            <input name="amount" type="number" {...register('amount')} className={`form-control ${errors.amount ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.amount?.message}</div>
                        </div>
                        <div className="col-6 col-12-medium container">
                            <div className='list-item-right-margin'>
                                <label className="form-label">Billing Date</label>
                                <div>
                                    <Controller
                                        control={control}
                                        name="billing_date"
                                        render={({ field }) => (
                                            <DatePicker
                                            className={`form-control input-size1 ${errors.billing_date ? 'is-invalid' : ''}`}
                                            placeholderText="Select date"
                                            onChange={(date) => field.onChange(date)}
                                            selected={field.value}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="invalid-feedback-date">{errors.billing_date?.message}</div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row ">
                        <div className="col-12 col-12-medium container ">
                            <label className="form-label">Expiration Date</label>
                            <div>
                            <Controller
                                control={control}
                                name="expiration_date"
                                render={({ field }) => (
                                    <DatePicker
                                    className={`form-control input-size1 ${errors.expiration_date ? 'is-invalid' : ''}`}
                                      placeholderText="Select date"
                                      onChange={(date) => field.onChange(date)}
                                      selected={field.value}
                                    />
                                )}
                            />
                            </div>
                            <div className="invalid-feedback-date">{errors.expiration_date?.message}</div>
                        </div>
                    </div>

                    <div className="row input-size1">
                        <div className="col-12 col-12-medium container btn-add">
                            <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                                {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                                Save
                            </button>
                            <Link to="/billing#root" className="btn btn-link">Cancel</Link>
                        </div>
                    </div>
                    
                </form>
            /*) : (
                <span className='fs-4 text-secondary'>Loading...</span>
            )*/}
            <div className='list-item'></div>
        </div>
        </>
    );
}
