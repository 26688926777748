import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from './_store';
import { App } from './App';
import './index.css';
//import "./responsive.css";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
//import 'bootstrap/dist/css/bootstrap.min.css';
import './logs.config.js'
import './styles';

// setup fake backend
/*import { fakeBackend } from './_helpers';
fakeBackend();
*/
if (process.env.REACT_APP_ENVIRONMENT === 'production'){
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
  }


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <ToastContainer />
        <BrowserRouter>
        
            <App />
        </BrowserRouter>
    </Provider>
);

/*
  <React.StrictMode>
    <Provider store={store}>
        <ToastContainer />
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
  </React.StrictMode>
*/