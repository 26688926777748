import { Routes, Route } from 'react-router-dom';
import { Home } from '.';

export { HomeLayout };

function HomeLayout() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Home />} />
            </Routes>
        </div>
    );
}
