import axios from 'axios';
import { toast } from 'react-toastify';
import { authActions,store } from '../_store';

const logout = () => store.dispatch(authActions.logout());

const URL_BASE_API = `${process.env.REACT_APP_URL_BASE_API}/api/auth`;

export const axiosInstance = axios.create({
  baseURL: URL_BASE_API
});


// Request interceptor for adding the bearer token
axiosInstance.interceptors.request.use(
  (config) => {
    //const token = TokenService.getLocalAccessToken();
    const token = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    if (token) {
      // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      config.headers["x-access-token"] = token; // for Node.js Express back-end
      config.headers["refresh_token"] = refreshToken; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


function createAxiosResponseInterceptor() {
  // Response interceptor
  const interceptor = axiosInstance.interceptors.response.use(
    (response) => {
      // Modify the response data here
      return response;
    },
    async (error) => {
      
      //const auth = JSON.parse(localStorage.getItem('auth'));
      //const originalRequest = error?.config
      // Handle response errors here
      const response = error?.response
      //const request = error?.request
      //const config = error?.config //here we have access the config used to make the api call (we can make a retry using this conf)

      let message = '';
      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_CONNECTION_RESET'){
        //The request was made but no response was received
        message = "Sorry, there's a problem. Please try again later.";
        toast.error(message, {
          position: "top-center"
        });
        return Promise.reject(error);
      } 
      
      // Reject promise if usual error
      if (error.response.status !== 401) {
        logout();
        toast.error(response.data.message, {
          position: "top-center"
        });
        return Promise.reject(error);
      }

      axiosInstance.interceptors.response.eject(interceptor);
        return  await axiosInstance.get("/refresh-token").then(res => {

          localStorage.setItem('accessToken', res.data.accessToken);
          axiosInstance.defaults.headers.common["x-access-token"] = localStorage.getItem('accessToken'); 
          console.log(res.data.message)
            // Resolves the promise if successful
          return axiosInstance(error.response.config);
        })
        .catch((error2) => {
            // Retry failed, clean up and reject the promise
            logout();
            toast.error("Session expired, please sign in again.", {
              position: "top-center"
            });
            return Promise.reject(error2);
        })
        .finally(createAxiosResponseInterceptor);
    }
  );
}

createAxiosResponseInterceptor();

// Export the api instance
export default axiosInstance;
