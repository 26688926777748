import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';

import { history } from './_helpers';
import { Alert, PrivateRoute, Header, Footer } from './_components';
import { AccountLayout } from './pages/account';
import { UsersLayout } from './pages/users';
import { BillingLayout } from './pages/billing';
import { Confirm } from './pages/confirm';
import { PublicLayout } from './pages/public/PublicLayout';
import { HomeLayout } from './pages/home';

export { App };

function App() {
    // init custom history object to allow navigation from 
    // anywhere in the react app (inside or outside components)
    history.navigate = useNavigate();
    history.location = useLocation();

    return (
        <div className="app-container">
            {/*<Nav />*/}
            <Alert />
            <Header />
            <div>
                <Routes>
                    {/* private */}
                    <Route element={<PrivateRoute />}>
                        <Route path="*" element={<HomeLayout />} />
                        <Route path="users/*" element={<UsersLayout />} />
                        <Route path="billing/*" element={<BillingLayout />} />
                        <Route path="confirm/*" element={<Confirm />} />

                    </Route>
                    {/* public */}
                    <Route path="account/*" element={<AccountLayout />} />
                    <Route path="*" element={<Navigate to="/" />} />
                    <Route path="public/*" element={<PublicLayout />} />
                </Routes>
            </div>
            <Footer />
        </div>
    );
}
