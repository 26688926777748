import { useDispatch } from 'react-redux';
import { useParams, Navigate } from 'react-router-dom';
import { history } from '../../_helpers';
import { userActions } from '../../_store';
import { toast } from 'react-toastify';
import { authActions } from '../../_store';

export { Verify };

function Verify() {
    
    const params = useParams();
    const dispatch = useDispatch();
    const auth = JSON.parse(localStorage.getItem('auth'));

    window.history.replaceState(null, "Verifying account...", "/account/verify")

    if(auth && auth.confirmed){
        return <Navigate to="/" />;
    } else {
        dispatch(userActions.verify(params.token)).unwrap().then(res => {
        
            if(auth){
                // set auth user in redux state
                dispatch(authActions.setAuth(res));

                // store new confirmed user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('auth', JSON.stringify(res));
                history.navigate('/');
            } else {
                history.navigate('/account/login');
            }            
    
            toast.success(res.message, {
                position: "top-center"
            });
        }).catch(err => {
            history.navigate('/');
        });
    }

    return (
            <h1>Verifying account...</h1>
            );
}
