import { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { useLocation } from "react-router-dom";
import { authActions } from '../_store';
import { SlLogout } from "react-icons/sl";
import { MdOutlinePayments } from "react-icons/md";
import { HiOutlineHome } from "react-icons/hi";
import { GrGroup } from "react-icons/gr";
import { IoClose, IoMenu } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import { VscAccount } from "react-icons/vsc";

export { Header };

function Header() {
    const auth = useSelector(x => x.auth.value);
    const dispatch = useDispatch();
    const logout = () => {
        dispatch(authActions.logout());
        closeMenuOnMobile();
    }
        
    const isMobile = useMediaQuery({ maxWidth: "1150px" });

    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
    setShowMenu(!showMenu);
    };

    const closeMenuOnMobile = () => {
        if (window.innerWidth <= 1150) {
        setShowMenu(false);
        }
    };


    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    // only show nav when logged in
    //if (!auth) return null;

    return (
            <>
            {isMobile ? (
                <div className="header-mobile-menu">
                    <nav className="nav container">
                        <NavLink to="/public#root" className="nav__logo">
                            Sistemaspy.com
                        </NavLink>
                    <div
                        className={"nav__menu "+ ((showMenu) ? "show-menu" : "show-menu-hide")} 
                        >
                        <ul className="">
                        {(auth && auth.confirmed) ? (
                            <>
                            <li className="show-menu-link">
                                <NavLink to="/" onClick={closeMenuOnMobile}>
                                    <span className="indent-0"></span>
                                    <HiOutlineHome />&nbsp;Home
                                </NavLink>
                            </li>
                            
                            <li className="show-menu-link">
                                <Link to="/users#root" onClick={closeMenuOnMobile}>
                                    <GrGroup />&nbsp;Users
                                </Link>
                            </li>
                            
                            <li className="show-menu-link">
                                <Link to="/billing#root" onClick={closeMenuOnMobile}>
                                    <MdOutlinePayments />&nbsp;Billing
                                </Link>
                            </li>
                            
                            <li className="show-menu-link">
                                <NavLink to="{logout}" onClick={logout} >
                                    <SlLogout />&nbsp;Logout
                                </NavLink>
                            </li>
                            </>
                        ) : (
                            <>
                            <li className="show-menu-link">
                                <Link to="/public#root" onClick={closeMenuOnMobile}>
                                        <span className="indent-0"></span>
                                        <HiOutlineHome />&nbsp;Home
                                </Link>
                            </li>
                            <li className="show-menu-link">
                                <Link to="/account/login#root" onClick={closeMenuOnMobile}>
                                    <VscAccount />&nbsp;Sing In
                                </Link>
                            </li>
                            </>
                        )}
                        </ul>
                        <div className="nav__close" id="nav-close" onClick={toggleMenu}>
                        <IoClose />
                        </div>
                    </div>

                    <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
                        <IoMenu />
                    </div>
                    </nav>
                </div>
            ) : (
                <div id="page-wrapper" className='header-body fixed-top'>
                    <div id="header-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">

                                    <header id="header">
                                        <h1><a href="index.html" id="logo">Sistemaspy.com</a></h1>

                                        <nav id="nav">
                                            {(auth && auth.confirmed) ? (
                                                <>
                                                <NavLink to="/" className={"nav-item nav-link "+ ((splitLocation[1] === "") ? "current-page-item" : "")}>
                                                    <HiOutlineHome />&nbsp;Home
                                                </NavLink>
                                                <NavLink to={{pathname: '/users', hash: '#root'}} className={"nav-item nav-link "+ ((splitLocation[1] === "users") ? "current-page-item" : "")}>
                                                    <GrGroup />&nbsp;Users
                                                </NavLink>
                                                <Link to="/billing#root" className={"nav-item nav-link "+ ((splitLocation[1] === "billing") ? "current-page-item" : "")}>
                                                    <MdOutlinePayments />&nbsp;Billing
                                                </Link>
                                                <NavLink to="{logout}" onClick={logout} className="nav-item nav-link">
                                                    <SlLogout />&nbsp;Logout
                                                </NavLink>
                                                </>
                                            ) : (
                                                <>
                                                <Link to="/public#root" className={"nav-item nav-link "+ ((splitLocation[1] === "public") ? "current-page-item" : "")}>
                                                    <HiOutlineHome />&nbsp;Home
                                                </Link>
                                                
                                                <Link to="/account/login#root" className={"nav-item nav-link "+ ((splitLocation[1] === "account") ? "current-page-item" : "")}>
                                                    <VscAccount />&nbsp;Sing In
                                                </Link>
                                                <Link to="/confirm#root" className={"nav-item nav-link "+ ((splitLocation[1] === "confirm") ? "current-page-item" : "")}>
                                                    <MdOutlinePayments />&nbsp;Confirm
                                                </Link>
                                                </>
                                            )}
                                        </nav>
                                    </header>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}            
            </>
    );
}