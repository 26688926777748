import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { userActions } from '../../_store';
import { history } from '../../_helpers';

function ResetPassword() {
    const TokenStatus = {
        Validating: 'Validating',
        Valid: 'Valid',
        Invalid: 'Invalid'
    }
    
    const [token, setToken] = useState(null);
    const [tokenStatus, setTokenStatus] = useState(TokenStatus.Validating);
    const dispatch = useDispatch();
/*
    useEffect(() => {
        const { token } = queryString.parse(location.search);

        // remove token from url to prevent http referer leakage
        //history.replace(location.pathname);
        window.history.replaceState(null, "Reseting password...", "/account/reset-password")

        accountService.validateResetToken(token)
            .then(() => {
                setToken(token);
                setTokenStatus(TokenStatus.Valid);
            })
            .catch(() => {
                setTokenStatus(TokenStatus.Invalid);
            });

            
    }, []);
*/

    const params = useParams();

    // remove token from url to prevent http referer leakage
    //history.replace(location.pathname);
    window.history.replaceState(null, "Reseting password...", "/account/reset-password")

    dispatch(userActions.generate(params.token)).unwrap().then(() => {
        setToken(params.token);
        setTokenStatus(TokenStatus.Valid);
    }).catch(() => {
        setTokenStatus(TokenStatus.Invalid);
    });

    function getForm() {
        const initialValues = {
            password: '',
            confirmPassword: ''
        };

        const getCharacterValidationError = (str) => {
            return `Your password must have at least 1 ${str} character`;
        };

        const validationSchema = Yup.object().shape({
            password: Yup.string()
                .min(6, 'Password must be at least 6 characters')
                .required('Password is required')
                .matches(/[0-9]/, getCharacterValidationError("digit"))
                .matches(/[a-z]/, getCharacterValidationError("lowercase"))
                .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
                .matches(/[!.@#$%^&*]/, getCharacterValidationError("!.@#$%^&*")),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Confirm Password is required'),
        });

        function onSubmit({ password, confirmPassword }, { setSubmitting }) {
            dispatch(userActions.reset({ token, password, confirmPassword })).unwrap().then(res => {
                history.navigate('/account/login');
                toast.success(res.message, {
                    position: "top-center"
                });
            }).catch(() => {
                setSubmitting(false);
            });
        }

        return (
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {({ errors, touched, isSubmitting }) => (
                    <Form>
                        <div className="form-group">
                            <label>Password</label>
                            <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                            <ErrorMessage name="password" component="div" className="invalid-feedback" />
                        </div>
                        <br/><br/>
                        <div className="form-group">
                            <label>Confirm Password</label>
                            <Field name="confirmPassword" type="password" className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
                            <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-row">
                            <div className="form-group col">
                                <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Reset Password
                                </button>
                                <Link to="/login" className="btn btn-link">Cancel</Link>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }

    function getBody() {
        switch (tokenStatus) {
            case TokenStatus.Valid:
                return getForm();
            case TokenStatus.Invalid:
                return (
                    <div>
                        Token validation failed, if the token has expired you can get a new one at the <Link to="../forgot-password">forgot password</Link> page.
                    </div>
                ); 
            case TokenStatus.Validating:
                return <div>Validating token...</div>;
            default:
                return null;
        }
    }

    return (
        <>
        <div className="container">
            <br/><br/><br/><br/>
            <div className="row">
                <h3 className="card-header">
                    <div className="p-3 mb-2 bg-dark text-white">Reset Password</div>
                </h3>
                <div className="card-body">
                    <div className="p-3 mb-2 bg-white text-dark">
                        <br/><br/>
                        {getBody()}
                        <br/><br/>
                    </div>
                </div>
            </div>
        </div>
        <br/><br/>
        </>
    )
}

export { ResetPassword };