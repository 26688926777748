import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { userActions } from '../../_store';

export { List };

function List() {
    const users = useSelector(x => x.users.list);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(userActions.getAll());
    }, []);

    return (
            <div className='nav-bottom top-padding-space'>
            <h2>Users</h2>
            <Link to="add" className="btn btn-sm btn-success mb-2">Add User</Link>
            <div>
                { users?.loading &&
                    <div className="text-center">
                        <span className="spinner-border spinner-border-lg align-center"></span>
                    </div>
                }
                
            </div>
            {users && users.value && Object.keys(users.value).length > 0 ? (
                <div className="table-container">
                <table className="table">
                    <thead>
                        <tr>
                            <th style={{ width: '30%' }}>ID</th>
                            <th style={{ width: '30%' }}>Email</th>
                            <th style={{ width: '30%' }}>Username</th>
                            <th style={{ width: '10%' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.values(users?.value).map(user =>
                            <tr key={user.id}>
                                <td>{user.id}</td>
                                <td>{user.email}</td>
                                <td>{user.username}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>
                                    <Link to={`edit/${user.id}`} className="btn btn-sm btn-primary me-1">Edit</Link>
                                    <button onClick={() => dispatch(userActions.delete(user.id))} className="btn btn-sm btn-danger" style={{ width: '60px' }} disabled={user.isDeleting}>
                                        {user.isDeleting 
                                            ? <span className="spinner-border spinner-border-sm"></span>
                                            : <span>Delete</span>
                                        }
                                    </button>
                                </td>
                            </tr>
                        )}
                        {users?.loading &&
                            <tr>
                                <td colSpan="4" className="text-center">
                                    <span className="spinner-border spinner-border-lg align-center"></span>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                </div>
            ) : (
                <span className='fs-4 text-secondary'>No results.</span>
            )}

        </div>
    );
}
