import { HashLink as Link } from 'react-router-hash-link';

export { Footer };

function Footer() {
    /*const auth = useSelector(x => x.auth.value);
    const dispatch = useDispatch();*/

    // only show footer when logged in
    //if (!auth) return null;

    return (
            <>
            <footer design-system="air-3-0" className="up-footer" data-v-859f4462="" data-test="LazyHydrate up-footer">
                <div role="heading" aria-level="2" className="sr-only">
                    Footer navigation
                </div>
                <div className="container">
                    
                    <div className="up-footer-columns">
                        <div>
                            <ul className="up-footer-list">
                                <li className="up-footer-list-item">
                                    <Link to="/footer#root" className="up-n-link up-footer-list-link">
                                        About Us
                                    </Link>
                                </li>
                                <li className="up-footer-list-item">
                                    <a href="/" target="_self" data-cy="feedback-link" className="up-n-link up-footer-list-link" data-test="UpLink">
                                    Feedback
                                    </a>
                                </li>
                                <li className="up-footer-list-item">
                                    <a href="http://community.upwork.com" target="_self" className="up-n-link up-footer-list-link" data-test="UpLink">
                                    Community
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <ul className="up-footer-list">
                                <li className="up-footer-list-item">
                                    <a href="/trust-and-safety/security/" target="_self" className="up-n-link up-footer-list-link" data-test="UpLink">
                                    Trust, Safety &amp; Security
                                    </a>
                                </li>
                                <li className="up-footer-list-item">
                                    <a href="https://support.upwork.com/login" target="_self" className="up-n-link up-footer-list-link" data-test="UpLink">
                                    Help &amp; Support
                                    </a>
                                </li>
                                <li className="up-footer-list-item">
                                    <a href="/ppc/foundation/" target="_self" className="up-n-link up-footer-list-link" data-test="UpLink">
                                    Partner with Us
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <ul className="up-footer-list">
                                <li className="up-footer-list-item">
                                    <a href="/legal#terms" target="_self" className="up-n-link up-footer-list-link" data-test="UpLink">
                                    Terms of Service
                                    </a>
                                </li>
                                <li className="up-footer-list-item">
                                    <a href="/legal#privacy" target="_self" className="up-n-link up-footer-list-link" data-test="UpLink">
                                    Privacy Policy
                                    </a>
                                </li>
                                <li className="up-footer-list-item">
                                    <a href="/" className="up-footer-list-link ot-sdk-show-settings">
                                        Explore All Features
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <ul className="up-footer-list">
                                <li className="up-footer-list-item">
                                    <a href="/legal#accessibility" target="_self" className="up-n-link up-footer-list-link" data-test="UpLink">
                                    FAQ
                                    </a>
                                </li>
                                <li className="up-footer-list-item">
                                    <a href="/legal#cookie-policy" target="_self" className="up-n-link up-footer-list-link" data-test="UpLink">
                                    Contact Us 
                                    </a>
                                </li>
                                <li className="up-footer-list-item">
                                    <a href="/enterprise" target="_self" className="up-n-link up-footer-list-link" data-test="UpLink">
                                    Enterprise Solutions
                                    </a>
                                </li>
                            </ul>
                        </div> 
                    </div>
                    
                    <div className="up-footer-social" data-test="FooterSocial">
                        <div className="up-footer-social-icons">
                            <div role="heading" aria-level="3" className="up-footer-social-header">
                            Follow Us
                            </div>
                            <ul className="up-footer-social-list">

                                <li className="up-footer-social-list-item">
                                    <a href="https://www.facebook.com/upwork" title="Visit Upwork on Facebook" target="_blank" rel="noopener noreferrer" className="up-footer-social-list-link">
                                        <span className="sr-only">Visit Upwork on Facebook</span>
                                        <div className="air3-icon md" data-test="UpCIcon">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" aria-hidden="true" viewBox="0 0 24 24" role="img">
                                                <path vectorEffect="non-scaling-stroke" stroke="var(--icon-color, #001e00)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M11.99 21V10.11a2 2 0 012-2h2.08m-6.949 5.13h6.18"></path>
                                                <path vectorEffect="non-scaling-stroke" stroke="var(--icon-color, #001e00)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 21a9 9 0 100-18 9 9 0 000 18z"></path>
                                            </svg>
                                        </div>
                                    </a>
                                </li>
                                <li className="up-footer-social-list-item">
                                    <a href="https://www.linkedin.com/company/upwork" title="Read Upwork company news on LinkedIn" target="_blank" rel="noopener noreferrer" className="up-footer-social-list-link">
                                        <span className="sr-only">Read Upwork company news on LinkedIn</span>
                                        <div className="air3-icon md" data-test="UpCIcon">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" aria-hidden="true" viewBox="0 0 24 24" role="img">
                                                <path fill="var(--icon-color, #001e00)" d="M19.67 3H4.33C3.59 3 3 3.59 3 4.33v15.34C3 20.4 3.59 21 4.33 21h15.34c.73 0 1.33-.59 1.33-1.33V4.33C21 3.6 20.41 3 19.67 3zM8.36 18.33H5.65V9.74h2.71v8.6-.01zM7.01 8.55c-.86 0-1.56-.66-1.56-1.55s.7-1.55 1.56-1.55c.86 0 1.56.66 1.56 1.55s-.7 1.55-1.56 1.55zm11.33 9.79h-2.71v-4.7c0-1.39-.59-1.81-1.35-1.81-.8 0-1.59.6-1.59 1.85v4.66H9.98v-8.6h2.6v1.19h.03c.26-.53 1.18-1.43 2.57-1.43 1.51 0 3.14.9 3.14 3.52v5.32h.02z"></path>
                                            </svg>
                                        </div>
                                    </a>
                                </li>
                                <li className="up-footer-social-list-item">
                                    <a href="https://twitter.com/Upwork" title="Follow @Upwork on Twitter" target="_blank" rel="noopener noreferrer" className="up-footer-social-list-link">
                                        <span className="sr-only">Follow @Upwork on Twitter</span>
                                        <div className="air3-icon md" data-test="UpCIcon">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" aria-hidden="true" viewBox="0 0 24 24" role="img">
                                                <path fill="var(--icon-color, #001e00)" d="M13.482 10.622L20.04 3h-1.554l-5.693 6.618L8.245 3H3l6.876 10.007L3 21h1.554l6.012-6.989L15.368 21h5.245l-7.131-10.378zm-2.128 2.473l-.697-.996-5.543-7.93H7.5l4.474 6.4.697.996 5.815 8.318h-2.387l-4.745-6.787z"></path>
                                            </svg>
                                        </div>
                                    </a>
                                </li>
                                <li className="up-footer-social-list-item">
                                    <a href="https://www.youtube.com/channel/UCvxGFOnwUBDHHcxuPqhe4CQ" title="Watch Upwork videos on YouTube" target="_blank" rel="noopener noreferrer" className="up-footer-social-list-link">
                                        <span className="sr-only">Watch Upwork videos on YouTube</span>
                                        <div className="air3-icon md" data-test="UpCIcon">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" aria-hidden="true" viewBox="0 0 24 24" role="img">
                                                <path vectorEffect="non-scaling-stroke" stroke="var(--icon-color, #001e00)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M19 6.384a2.24 2.24 0 011.59 1.6c.274 1.45.412 2.923.41 4.4a23.468 23.468 0 01-.38 4.36A2.24 2.24 0 0119 18.382c-2.323.28-4.661.407-7 .38-2.339.027-4.677-.1-7-.38a2.24 2.24 0 01-1.59-1.6 23.47 23.47 0 01-.41-4.4 23.526 23.526 0 01.38-4.36A2.24 2.24 0 015 6.384a53.345 53.345 0 017-.38c2.339-.027 4.677.1 7 .38v0z" clipRule="evenodd"></path>
                                                <path vectorEffect="non-scaling-stroke" stroke="var(--icon-color, #001e00)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M14.87 12.383l-4.71-2.68v5.36l4.71-2.68z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </a>
                                </li>
                                <li className="up-footer-social-list-item">
                                    <a href="https://www.instagram.com/upwork/" title="Follow Upwork on Instagram" target="_blank" rel="noopener noreferrer" className="up-footer-social-list-link">
                                        <span className="sr-only">Follow Upwork on Instagram</span>
                                        <div className="air3-icon md" data-test="UpCIcon">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" aria-hidden="true" viewBox="0 0 24 24" role="img">
                                                <path vectorEffect="non-scaling-stroke" stroke="var(--icon-color, #001e00)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M16 3H8a5 5 0 00-5 5v8a5 5 0 005 5h8a5 5 0 005-5V8a5 5 0 00-5-5z"></path>
                                                <path vectorEffect="non-scaling-stroke" stroke="var(--icon-color, #001e00)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 16a4 4 0 100-8 4 4 0 000 8z"></path>
                                                <path fill="var(--icon-color, #001e00)" vectorEffect="non-scaling-stroke" stroke="var(--icon-color, #001e00)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M17.34 7a.35.35 0 100-.7.35.35 0 000 .7z"></path>
                                            </svg>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="up-footer-social-icons up-footer-download">
                            <div role="heading" aria-level="3" className="up-footer-social-header">
                            Mobile app
                            </div>
                            <ul className="up-footer-social-list">
                                <li className="up-footer-social-list-item">
                                    <a href="https://apps.apple.com/us/developer/upwork-global-inc/id329377609" title="Download Upwork app from iTunes" target="_blank" rel="noopener noreferrer" className="up-footer-social-list-link">
                                        <span className="sr-only">Download Upwork app from iTunes</span>
                                        <div className="air3-icon md" data-test="UpCIcon">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" aria-hidden="true" viewBox="0 0 24 24" role="img">
                                                <path vectorEffect="non-scaling-stroke" stroke="var(--icon-color, #001e00)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M15.117 3a3.907 3.907 0 01-.87 2.918 3.607 3.607 0 01-2.778 1.409 3.657 3.657 0 01.9-2.848A4.287 4.287 0 0115.117 3z" clipRule="evenodd"></path>
                                                <path vectorEffect="non-scaling-stroke" stroke="var(--icon-color, #001e00)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M18.295 18.381c.453-.69.844-1.42 1.17-2.178a3.997 3.997 0 01-.53-7.204 4.586 4.586 0 00-3.458-1.83c-.705.01-1.401.16-2.048.44-.433.22-.905.353-1.389.39a4.726 4.726 0 01-1.559-.36c-.6-.25-1.24-.388-1.888-.41a4.676 4.676 0 00-3.777 2.26c-1.31 1.998-1.09 5.805.999 8.992.79 1.2 1.808 2.508 3.127 2.508.46-.01.91-.123 1.32-.33a3.997 3.997 0 013.587 0c.395.217.838.334 1.289.34 1.329-.01 2.398-1.459 3.157-2.618z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                    </a>
                                </li>
                                <li className="up-footer-social-list-item">
                                    <a href="https://play.google.com/store/apps/dev?id=8802639270489632480" title="Download Upwork app from Google Play" target="_blank" rel="noopener noreferrer" className="up-footer-social-list-link">
                                        <span className="sr-only">Download Upwork app from Google Play</span>
                                        <div className="air3-icon md" data-test="UpCIcon">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" aria-hidden="true" viewBox="0 0 24 24" role="img">
                                                <path fill="var(--icon-color, #001e00)" vectorEffect="non-scaling-stroke" stroke="var(--icon-color, #001e00)" strokeMiterlimit="10" strokeWidth=".4" d="M9.893 6.726a.44.44 0 100-.88.44.44 0 000 .88zm3.522 0a.44.44 0 100-.88.44.44 0 000 .88z"></path>
                                                <path vectorEffect="non-scaling-stroke" stroke="var(--icon-color, #001e00)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M11.656 3.83a5 5 0 00-5.23 4.82h10.46a5 5 0 00-5.23-4.82zm-2.501.58L8.375 3m5.791 1.41l.77-1.41"></path>
                                                <path vectorEffect="non-scaling-stroke" stroke="var(--icon-color, #001e00)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M16.896 8.648H6.426v9.2h10.47v-9.2zM4 8.648v6.3m15.314-6.3v6.3M9.57 17.85V21m4.174-3.15V21"></path>
                                            </svg>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div> 
                    <p className="up-footer-copyright">
                        <span>
                            © 2012 - 2024 Sistemaspy.com® Global Inc.
                        </span>
                    </p>
                </div>
            </footer>
            </>
    );
}