import { Routes, Route } from 'react-router-dom';
import { AboutUs, Home } from '.';

export { PublicLayout };

function PublicLayout() {
    return (
        <div>
            <Routes>
                <Route index element={<Home />} />
                <Route path="about" element={<AboutUs />} />
            </Routes>
        </div>
    );
}
