import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../_helpers';

const URL_BASE_API = `${process.env.REACT_APP_URL_BASE_API}/api/`;

// create slice
const name = 'payment-type';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports
export const paymentTypeActions = { ...slice.actions, ...extraActions };
export const paymentTypeReducer = slice.reducer;

// implementation
function createInitialState() {
    return {
        list: null,
        item: null
    }
}

function createExtraActions() {

    return {
        getAll: getAll(),
        getById: getById()
    };

    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async () => (await axiosInstance.get(URL_BASE_API + "payment-type")).data
        );
    }

    function getById() {
        return createAsyncThunk(
            `${name}/getById`,
            async (id) => (await axiosInstance.get(`${URL_BASE_API}payment-type/${id}`)).data
        );
    }
}

function createExtraReducers() {
    return (builder) => {
        getAll();
        getById();

        function getAll() {
            var { pending, fulfilled, rejected } = extraActions.getAll;
            builder
                .addCase(pending, (state) => {
                    state.list = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.list = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.list = { error: action.error };
                });
        }

        function getById() {
            var { pending, fulfilled, rejected } = extraActions.getById;
            builder
                .addCase(pending, (state) => {
                    state.item = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.item = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.item = { error: action.error };
                });
        }
    }
}
