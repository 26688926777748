import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import { history } from '../../_helpers';
import { userActions } from '../../_store';
import { toast } from 'react-toastify';

function ForgotPassword() {
    
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required')
    });
    
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting, touchedFields } = formState;

    async function onSubmit(data) {
        //dispatch(alertActions.clear());
        try {
            await dispatch(userActions.forgot(data.email)).unwrap();

            // redirect to login page and display success alert
            history.navigate('/account/login');
            toast.success('Reset Password Email sent, please check your email to complete the process.', {
                position: "top-center"
            });
            /*dispatch(alertActions.success({ message: 'Registration successful', showAfterRedirect: true }));
            setTimeout(() => {
                dispatch(alertActions.clear());
              }, 4000);
            dispatch(alertActions.clear());*/
        } catch (error) {}
    }

    return (
        <div className='nav-bottom top-padding-space list-item'>
            <div className="container overflow-hidden">
                <br/><br/><br/>
                <form onSubmit={handleSubmit(onSubmit)}>
                <br/>
                <h3 className="card-header">
                    <span className="d-block p-2 bg-dark text-white register-header">
                        Forgot Password
                    </span>
                </h3>
                <span className="d-block p-2 bg-primary text-white">Enter your email to get a password recovery link.</span>
                <br/>
                    <div className="card-body">
                        <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input id='email-fp' name="email" type="text" {...register('email')} className={`form-control input-size1 ${errors.email && touchedFields.email ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback">{errors.email?.message}</div>
                        </div>
                        <div className="form-row margin-bottom-forgot-password">
                            <div className="form-group col">
                                <button disabled={isSubmitting} className="btn btn-primary input-size1">
                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Submit
                                </button>
                                <Link to="login" className="btn btn-link input-size1">Cancel</Link>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export { ForgotPassword };