import { configureStore } from '@reduxjs/toolkit';

import { alertReducer } from './alert.slice';
import { authReducer } from './auth.slice';
import { usersReducer } from './users.slice';
import { billingReducer } from './billing.slice';
import { paymentTypeReducer } from './payment_type.slice';

export * from './alert.slice';
export * from './auth.slice';
export * from './users.slice';
export * from './billing.slice';
export * from './payment_type.slice';

export const store = configureStore({
    reducer: {
        alert: alertReducer,
        auth: authReducer,
        users: usersReducer,
        billing: billingReducer,
        payment_type: paymentTypeReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
