import { Routes, Route } from 'react-router-dom';
import { List, AddEdit } from '.';

export { BillingLayout };

function BillingLayout() {
    return (
        <div>
            <div className="container initial-height-screen">
                <Routes>
                    <Route index element={<List />} />
                    <Route path="add" element={<AddEdit />} />
                    <Route path="edit/:id" element={<AddEdit />} />
                </Routes>
            </div>
        </div>
    );
}
