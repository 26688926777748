import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../_helpers';

const URL_BASE_API = `${process.env.REACT_APP_URL_BASE_API}/api/`;


// create slice
const name = 'billing';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports
export const billingActions = { ...slice.actions, ...extraActions };
export const billingReducer = slice.reducer;

// implementation
function createInitialState() {
    return {
        list: null,
        item: null
    }
}

function createExtraActions() {

    return {
        register: register(),
        getAll: getAll(),
        getAllSearch: getAllSearch(),
        getById: getById(),
        update: update(),
        delete: _delete()
    };

    function register() {
        return createAsyncThunk(
            `${name}/register`,
            async (payment) => (await axiosInstance.post(URL_BASE_API + "payment", payment)).data
        );
    }

    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async (data) => (await axiosInstance.get(URL_BASE_API + "payment/"+data.limit+"/"+ ((data.page * data.limit) - data.limit) )).data
        );
    }

    /*function getAllSearch() {
        try{ 
            return createAsyncThunk(
                `${name}/getAllSearch`,
                async (data) => (await axiosInstance.get(URL_BASE_API + "payment-search/"+((data.term!=='')?data.term:null)+"/"+data.limit+"/"+ ((data.page * data.limit) - data.limit) )).data
            );
        }
        catch(err){
           // handle rejection
           console.error(">>>>x****>"+err)
        }        
    }*/


    function getAllSearch() {
    
        return createAsyncThunk(
            `${name}/getAllSearch`,
            async (data, { rejectWithValue }) => {

                try{ 
                    const response = await axiosInstance.get(URL_BASE_API + "payment-search/"+((data.term!=='')?data.term:null)+"/"+data.limit+"/"+ ((data.page * data.limit) - data.limit) )
                    return response.data
                }
                catch(err){
                    // handle rejection
                    console.error('aaaa')
                    console.log('ghjgjaaaa')
                    //return Promise.reject(err);
                    return rejectWithValue(err.response?.data)
                }
            }
        );   
    }
    
    function getById() {
        return createAsyncThunk(
            `${name}/getById`,
            async (id, { rejectWithValue }) => {
                try{ 
                    const response = await await axiosInstance.get(`${URL_BASE_API}payment/${id}`)
                    return response.data
                }
                catch(err){
                    // handle rejection
                    return rejectWithValue(err.response?.data)
                }
            }
        );
    }

    function update() {
        return createAsyncThunk(
            `${name}/update`,
            async function ({ id, data }, { getState, dispatch }) {
                await axiosInstance.put(URL_BASE_API + "payment", data)
            }
        );
    }

    // prefixed with underscore because delete is a reserved word in javascript
    function _delete() {
        return createAsyncThunk(
            `${name}/delete`,
            async function (id, { getState, dispatch }) {
                await axiosInstance.delete(`${URL_BASE_API}payment/${id}`)
            }
        );
    }
}

function createExtraReducers() {
    return (builder) => {
        getAll();
        getById();
        //_delete();
        getAllSearch();

        function getAll() {
            var { pending, fulfilled, rejected } = extraActions.getAll;
            builder
                .addCase(pending, (state) => {
                    state.list = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.list = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.list = { error: action.error };
                });
        }

        function getById() {
            var { pending, fulfilled, rejected } = extraActions.getById;
            builder
                .addCase(pending, (state) => {
                    state.item = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.item = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.item = { error: action.error };
                });
        }

        /*
        function _delete() {
            var { pending, fulfilled, rejected } = extraActions.delete;
            builder
                .addCase(pending, (state, action) => {
                    const user = state.list.value.find(x => x.id === action.meta.arg);
                    user.isDeleting = true;
                })
                .addCase(fulfilled, (state, action) => {
                    state.list.value = state.list.value.filter(x => x.id !== action.meta.arg);
                })
                .addCase(rejected, (state, action) => {
                    const user = state.list.value.find(x => x.id === action.meta.arg);
                    user.isDeleting = false;
                });
        }
        
        function _delete() {
            var { pending, fulfilled, rejected } = extraActions.delete;
            builder
                .addCase(pending, (state) => {
                    state.list = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.list = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.list = { error: action.error };
                });
        }*/

        /****goood*/
        function getAllSearch() {
            var { pending, fulfilled, rejected } = extraActions.getAllSearch;
            builder
                .addCase(pending, (state) => {
                    state.list = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.list = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.list = { error: action.error };
                });
        } 

        /*
        function getAllSearch() {
            var { pending, fulfilled, rejected } = extraActions.getAllSearch;
            builder
            .addCase(pending, (state, action) => {
                if (state.loading === 'idle') {
                  state.loading = 'pending'
                  state.currentRequestId = action.meta.requestId
                }
              })
              .addCase(fulfilled, (state, action) => {
                const { requestId } = action.meta
                if (
                  state.loading === 'pending' &&
                  state.currentRequestId === requestId
                ) {
                  state.loading = 'idle'
                  state.entities.push(action.payload)
                  state.currentRequestId = undefined
                }
              })
              .addCase(rejected, (state, action) => {
                const { requestId } = action.meta
                if (
                  state.loading === 'pending' &&
                  state.currentRequestId === requestId
                ) {
                  state.loading = 'idle'
                  state.error = action.error
                  state.currentRequestId = undefined
                }
              });
        }
*/



    }
}
