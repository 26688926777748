import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../../_store';
import { TiKeyOutline } from "react-icons/ti";
import { TiGift } from "react-icons/ti";
import { TiCreditCard } from "react-icons/ti";
import { TiCalendarOutline } from "react-icons/ti";
import { TfiReceipt } from "react-icons/tfi";
import { LuGem } from "react-icons/lu";
import { LuLogOut } from "react-icons/lu";
import { LuLineChart } from "react-icons/lu";
import image1 from "../../assets/images/pic5.jpg";

export { AboutUs };

function AboutUs() {
    const auth = useSelector(x => x.auth.value);
    const dispatch = useDispatch();
    const logout = () => dispatch(authActions.logout());

    return (
        <div className='nav-bottom initial-height-screen'>

            <div className="row ">
                <div className="col-3 col-12-medium container">
                    <section>
                        <h2 className='dashboard-title'>Dashboard</h2>
                        <ul className="dashboard-link-list">
                            <li><a href="/">
                                <TiKeyOutline /> Password manager
                                </a>
                            </li>
                            <li><a href="/">
                                <TiGift/> Refer Friends
                                </a>
                            </li>
                            <li><a href="/">
                                <TiCreditCard/> Add Credit Card
                                </a>
                            </li>
                            <li><a href="/">
                                <TiCalendarOutline/> Subscription
                                </a>
                            </li>
                            <li><a href="/">
                                <TfiReceipt/> Receipts
                                </a>
                            </li>
                            <li><a href="/">
                                <LuLineChart/> Reports and Stats
                                </a>
                            </li>
                            <li><a href="/">
                                <LuGem/> Premium content
                                </a>
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h1 className='welcome-title'>Hi {auth?.username} !!</h1>
                        <p>You're logged in with your premium account.</p>
                        <h3>
                            <Link to="logout" onClick={logout}>
                                <LuLogOut/> Sing out
                            </Link>
                        </h3>
                    </section>
                </div>

                <div id='imp-medium' className="col-9 col-12-medium container">
                    <section className='content-one-column'>
                        <div className="container">
                            <div className="row aln-center">
                                <div className="col-12 col-12-medium container">
                                        <section>
                                            <header>
                                                <h2>About Us</h2>
                                                <h3>A subheading about who we are</h3>
                                            </header>
                                            <a href="/" className="feature-image"><img src={image1} alt="" /></a>
                                            <p>
                                                Duis neque nisi, dapibus sed mattis quis, rutrum accumsan magna sed.
                                                Suspendisse eu varius nibh. Suspendisse vitae magna eget odio amet mollis
                                                justo facilisis quis. Sed sagittis amet lorem ipsum.
                                            </p>
                                        </section>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

        </div>
    );
}
